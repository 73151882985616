import React, { useEffect, createRef } from 'react';
import { FontAwesomeButton, InvertingSVGVanillaLink } from 'src/styles';
import { GlobalStyleVariables } from 'src/styles/global';
import ReactModal from 'react-modal';
import NotificationList from 'src/components/notification-list';
import { User } from 'src/types';
import styled from 'styled-components';
import { fibonacci, goldenRatio, goldenRatioInverse } from 'src/utils/math';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import StyledScrollbar from 'src/components/styled-scrollbar';

const Container = styled.div`
  display: flex;
`;

const StyledNotificationList = styled(NotificationList)`
  width: ${fibonacci(10)}rem;
  max-width: 96%;
`;

const ToggleContainer = styled(InvertingSVGVanillaLink)`
  position: relative;
`;

const UnreadCountContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  height: ${fibonacci(1) * goldenRatio}rem;
  width: ${fibonacci(1) * goldenRatio}rem;
  font-size: ${GlobalStyleVariables.fontSizeOneDown}rem;
  background-color: rgba(${(props): string => props.theme.errorColor}, 1);
`;

const ViewAllLink = styled.a`
  display: block;
  padding: ${fibonacci(3)}rem;
  text-align: right;
  cursor: pointer;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    padding: 1rem;
  }
`;

const ReStyledScrollbar = styled(StyledScrollbar)`
  max-height: ${fibonacci(10)}rem;
  margin-right: 1px;
  padding-right: ${fibonacci(4)}rem;

  @media ${GlobalStyleVariables.phoneMediaQuery} {
    max-height: calc(94svh - ${GlobalStyleVariables.headerHeight}rem);
    padding-right: ${fibonacci(1) * goldenRatioInverse}rem;
  }
`;

interface InterfaceProps {
  menuIsOpen: boolean;
  setMenuIsOpen: (state: boolean) => void;
  user: User;
}

const HeaderNotificationsMenu: React.FC<InterfaceProps> = ({
  menuIsOpen,
  setMenuIsOpen,
  user,
}) => {
  const { t } = useTranslation('general');
  const navigate = useNavigate();
  const ref = createRef<HTMLAnchorElement>();

  const toggleMenu = (): void => {
    setMenuIsOpen(!menuIsOpen);
  };

  const navigateToNotifications = (): void => {
    setMenuIsOpen(false);
    navigate('/notifications');
  };

  function onKeyDown(event: KeyboardEvent): void {
    if (
      event.key === 'Enter' &&
      ref.current &&
      ref.current === document.activeElement
    ) {
      event.preventDefault();
      toggleMenu();
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return (): void => {
      document.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Container>
      <ToggleContainer onClick={toggleMenu} tabIndex={0} ref={ref}>
        <FontAwesomeButton icon="bell" />
        {user.hasUnseenNotifications && <UnreadCountContainer />}
      </ToggleContainer>

      <ReactModal
        ariaHideApp={process.env.NODE_ENV !== 'test'}
        isOpen={menuIsOpen}
        onRequestClose={toggleMenu}
        closeTimeoutMS={0}
        className="UserMenuModal"
        overlayClassName="UserMenuModalOverlay"
      >
        <ReStyledScrollbar>
          <StyledNotificationList onClick={toggleMenu} />
        </ReStyledScrollbar>
        <ViewAllLink onClick={navigateToNotifications} tabIndex={1}>
          {t('notificationsViewAll')}
        </ViewAllLink>
      </ReactModal>
    </Container>
  );
};

export default HeaderNotificationsMenu;
