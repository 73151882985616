import React, { useState } from 'react';
import styled from 'styled-components';
import { User } from 'src/types';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DashDotDotButtonSet,
  Ellipsis,
  GlobalStyleVariables,
  InvertingHoverButton,
} from 'src/styles';
import Summary from 'src/components/summary';
import { Avatar } from 'src/components/avatar';
import UserSummarySumplementaryInfo from 'src/components/user-summary/user-summary-supplementary-info';
import UserSummaryPrimaryButton from 'src/components/user-summary/user-summary-primary-button';
import UserSummarySubscribeButton from 'src/components/user-summary/user-summary-subscribe-button';
import UserSummaryForm from 'src/components/user-summary/user-summary-form';
import { parseMarkdown } from 'src/utils/security';
import UserSummaryMenu from 'src/components/user-summary/user-summary-menu';

const NameContainer = styled.div`
  margin-bottom: 2rem;
`;

const Username = styled.div`
  margin-top: ${goldenRatioInverse}rem;
  color: rgba(
    ${(props): string => props.theme.highMutedColor},
    ${goldenRatioInverse}
  );
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
`;

const StyledDashDotDotButtonSet = styled(DashDotDotButtonSet)`
  max-width: ${(1 - goldenRatioInverse) * 100}rem;
  margin-bottom: ${fibonacci(4)}rem;
`;

interface InterfaceProps {
  user: User;
}

const UserSummaryView: React.FC<InterfaceProps> = ({ user }) => {
  const [isEditing, setIsEditing] = useState(false);

  function toggleEdit(): void {
    setIsEditing(!isEditing);
  }

  return (
    <Summary>
      {isEditing ? (
        <UserSummaryForm user={user} toggleForm={toggleEdit} />
      ) : (
        <>
          <section className="summary__lead">
            <Avatar
              avatar={user.avatar}
              size={(1 - goldenRatioInverse) * 100}
            />
          </section>
          <section className="summary__primary">
            <h1 className="summary__header">
              <NameContainer>
                {user.displayName ? (
                  <Ellipsis>{user.displayName}</Ellipsis>
                ) : undefined}
                <Username>
                  <Ellipsis>@{user.username}</Ellipsis>
                </Username>
              </NameContainer>
            </h1>
            <Ellipsis className="summary__supplementary-list">
              <UserSummarySumplementaryInfo user={user} />
            </Ellipsis>
            <div className="summary__buttons">
              <StyledDashDotDotButtonSet>
                <UserSummaryPrimaryButton toggleEdit={toggleEdit} user={user} />
                <UserSummarySubscribeButton user={user} />
                <InvertingHoverButton>
                  <FontAwesomeIcon icon="share" />
                </InvertingHoverButton>
                <UserSummaryMenu user={user} />
              </StyledDashDotDotButtonSet>
            </div>
            <Ellipsis
              className="summary__main"
              dangerouslySetInnerHTML={parseMarkdown(user.profile.description)}
            />
          </section>
        </>
      )}
    </Summary>
  );
};

export default UserSummaryView;
