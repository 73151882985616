/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEnsureUser } from 'src/hooks/use-ensure-user';
import { useMutation } from '@apollo/client';
import { PURCHASE_ALBUM_MUTATION } from 'src/queries/membership';
import { Post } from 'src/types';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import { toast } from 'react-toastify';
import { useAccountModals } from 'src/hooks/use-account-modals';
import { useReloadAndViewPost } from 'src/hooks/use-reload-and-view-post';
import { useConfirmationModal } from './use-confirmation-modal';
import { postTitle } from 'src/utils/formatting';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';

export function usePurchasePost(
  post: Post
): (_: any, { setSubmitting }: any) => Promise<void> {
  const { t } = useTranslation('general');
  const { openModal } = useAccountModals();
  const confirm = useConfirmationModal();
  const ensureUser = useEnsureUser();
  const onReloadCurrentPost = useReloadAndViewPost(post);

  const [purchasePostMutation] = useMutation(PURCHASE_ALBUM_MUTATION, {
    update(cache) {
      cache.writeFragment({
        id: `Post:${post.id}`,
        fragment: gql`
          fragment post on Post {
            purchaseCount
            userHasPurchased
          }
        `,
        data: {
          purchaseCount: post.purchaseCount + 1,
          userHasPurchased: true,
        },
      });
    },
  });
  const handleGraphQLErrors = useGraphQLErrors();

  return async (_: any, { setSubmitting }: any): Promise<void> => {
    try {
      if (!purchasePostMutation || !openModal) return;

      await ensureUser();

      if (
        await confirm(
          t('confirmPurchase', {
            amount: post.price,
            title: postTitle(post),
          })
        )
      ) {
        await purchasePostMutation({
          variables: {
            postId: post.id,
          },
        });

        await onReloadCurrentPost();
      }
    } catch (e) {
      handleGraphQLErrors(e as Error, (message) => {
        toast(message, { type: 'error' });
      });
    } finally {
      if (setSubmitting) {
        setSubmitting(false);
      }
    }
  };
}
