import { FileUpload, Post, PostEditInput, VideoInput } from 'src/types';

export async function convertPostToPostEditInput(
  post: Post
): Promise<PostEditInput> {
  const postInput = JSON.parse(JSON.stringify(post)) as PostEditInput;

  await Promise.all(
    postInput.mediaCollection.mediaItems.map(async (mediaItem) => {
      return new Promise((resolve, reject) => {
        const iOrV = mediaItem.image ?? mediaItem.video;
        const src = iOrV?.src;

        if (!src) reject();

        fetch(src as string, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Origin: window.location.origin,
          },
        })
          .then(function (response) {
            return response.blob();
          })
          .catch(() => reject())
          .then(function (blob) {
            if (blob) {
              const fileUpload = {
                id: iOrV?.id,
                mediaItemId: mediaItem.id,
                modifications: {},
                modifiedFile: blob as File,
                zoomIntensity: iOrV?.zoomIntensity,
                zoomX: iOrV?.zoomX,
                zoomY: iOrV?.zoomY,
                previewType: iOrV?.previewType,
                previewSrc: iOrV?.previewSrc,
                previewMediaType: (iOrV as VideoInput)?.previewMediaType,
                previewable: mediaItem.previewable,
                result: URL.createObjectURL(blob),
                type: mediaItem.image
                  ? 'image'
                  : mediaItem.video
                  ? 'video'
                  : 'unknown',
                file: blob as File,
              } as FileUpload;
              mediaItem.fileUpload = fileUpload;
              resolve(fileUpload);
            } else {
              reject();
            }
          });
      });
    })
  );

  return postInput;
}
