import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import {
  FileFilter,
  FilePreviewMediaType,
  FileUpload,
  SimpleFile,
} from 'src/types';
import NoContent from '../no-content';
import { fibonacci } from 'src/utils/math';
import { convertToPreviewFile } from './utils';

const StyledVideo = styled.video``;

const ImageElement = styled.img`
  height: 100%;
`;

const Container = styled.div`
  position: relative;
  min-width: ${fibonacci(8)}rem;
`;

const StyledNoContent = styled(NoContent)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

interface InterfaceProps {
  accept?: string;
  className?: string;
  file?: FileUpload;
  index?: number;
  isEditing?: boolean;
  mode?: string;
  onClick?: () => void;
  originalFile?: SimpleFile;
  replaceFile?: (index: number, file: FileUpload) => boolean;
}

const FileUploaderVideo: React.FC<InterfaceProps> = ({
  accept = '.jpg,.jpeg,.png,.webm,.mpg,.mp2,.mpeg,.mpe,.mpv,.mp4,.m4p,.m4v,.ogg,.avi',
  className,
  file,
  index,
  isEditing,
  mode,
  onClick,
  originalFile,
  replaceFile,
}) => {
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const onChange = useCallback(
    async (event: Event): Promise<void> => {
      const newFiles = (event.target as HTMLInputElement).files;

      if (!newFiles || newFiles.length === 0 || !file) return;

      const previewFile = await convertToPreviewFile(newFiles[0]);
      file.previewMediaType = previewFile.type;
      file.previewResult = previewFile.result;
      file.previewFile = previewFile.file;
      file.filteredModifiedFile = previewFile.file;
      forceUpdate();

      if (replaceFile && index !== undefined) {
        replaceFile(index, file);
      }
    },
    [file, replaceFile, index]
  );

  useEffect(() => {
    if (ref) {
      ref.value = '';
      ref.addEventListener('input', onChange);

      return (): void => {
        ref.removeEventListener('input', onChange);
      };
    }
  }, [onChange, ref]);

  useEffect(() => {
    if (
      ref &&
      file?.previewType === FileFilter.manual &&
      !file.previewFile &&
      !isEditing
    ) {
      ref.click();
    }
  }, [file?.previewType, ref]);

  if (mode === 'filter' && file?.previewType === FileFilter.manual) {
    return (
      <Container>
        {file?.previewMediaType === FilePreviewMediaType.video ? (
          <StyledVideo
            className={className}
            src={
              file?.previewResult ||
              originalFile?.previewSrc ||
              file?.previewSrc
            }
            onClick={onClick}
            autoPlay
            loop
            muted
          />
        ) : file?.previewMediaType === FilePreviewMediaType.image ? (
          <ImageElement
            className={className}
            src={
              file?.previewResult ||
              originalFile?.previewSrc ||
              file?.previewSrc
            }
          />
        ) : (
          <StyledNoContent icon="upload">
            <div />
          </StyledNoContent>
        )}
        {!isEditing && (
          <UploadInput
            type="file"
            ref={setRef}
            multiple={false}
            accept={accept}
          />
        )}
      </Container>
    );
  } else if (mode === 'filter' && file?.previewType === FileFilter.locked) {
    return (
      <Container>
        <StyledNoContent icon="lock">
          <div />
        </StyledNoContent>
      </Container>
    );
  } else {
    return (
      <StyledVideo
        className={className}
        src={file?.result || originalFile?.src}
        onClick={onClick}
        autoPlay
        loop
        muted
      />
    );
  }
};

export default FileUploaderVideo;
