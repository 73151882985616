// taken heavily from:
// https://usehooks.com/useAuth/

import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from 'react';
import { User } from 'src/types';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  LOGIN_MUTATION,
  CREATE_USER_MUTATION,
  CURRENT_USER_QUERY,
} from 'src/queries';
import { setItem, removeItem, getItem } from 'src/utils/storage';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Channel } from 'phoenix';
import { updateCurrentUser } from 'src/utils/data';
import { useSocket } from 'src/hooks/use-socket';

interface ContextProps {
  startedWithAuthToken: boolean;
  login: (variables: LoginProps) => Promise<User>;
  logout: () => void;
  register: (variables: RegisterProps) => Promise<User>;
  user?: User;
}

interface LoginProps {
  identity: string;
  password: string;
}

interface RegisterProps {
  email: string;
  password: string;
  username: string;
}

const AuthContext = createContext<Partial<ContextProps>>({
  startedWithAuthToken: getItem('auth:token'),
});

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = (): Partial<ContextProps> => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider(): ContextProps {
  const client = useApolloClient();
  const socket = useSocket();
  const [user, setUser] = useState<User>();
  const [channel, setChannel] = useState<Channel>();
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const [createUserMutation] = useMutation(CREATE_USER_MUTATION);
  const [loadCurrentUser] = useLazyQuery(CURRENT_USER_QUERY);
  const { data } = useQuery(CURRENT_USER_QUERY, {
    skip: !getItem('auth:token'),
  });

  const setupSession = useCallback(
    (currentUser?: User): void => {
      setUser(currentUser);

      if (currentUser && !channel) {
        setChannel(
          socket.channel(`notification:${currentUser.id}`, {
            token: getItem('auth:token'),
          })
        );
      }
    },
    [setUser, channel, setChannel]
  );

  useEffect(() => {
    if (channel) {
      channel
        .join()
        .receive('ok', (resp: string) => {
          console.log('Joined successfully', resp);
        })
        .receive('error', (resp: string) => {
          console.log('Unable to join', resp);
        });

      channel.on(`mark_user_as_having_unseen_notifications`, () => {
        updateCurrentUser(client.cache, () => {
          return {
            hasUnseenNotifications: true,
          };
        });
      });
    }
  }, [channel]);

  if (data && data.currentUser && data.currentUser !== user) {
    setupSession(data.currentUser);
  }

  const login = async (variables: LoginProps): Promise<User> => {
    const {
      data: {
        login: { token },
      },
    } = await loginMutation({
      variables,
    });
    setItem('auth:token', token);

    const {
      data: { currentUser },
    } = await loadCurrentUser();

    setupSession(currentUser);

    return currentUser;
  };

  const register = async (variables: RegisterProps): Promise<User> => {
    const {
      data: {
        createUser: { token },
      },
    } = await createUserMutation({
      variables,
    });
    setItem('auth:token', token);

    const {
      data: { currentUser },
    } = await loadCurrentUser();

    setupSession(currentUser);

    return currentUser;
  };

  const logout = (): void => {
    removeItem('auth:token');
    setupSession(undefined);
    window.location.assign(window.location.origin);
  };

  // Return the user object and auth methods
  return {
    startedWithAuthToken: getItem('auth:token'),
    login,
    logout,
    register,
    user,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuthProvider();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
