/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useState } from 'react';
import { StyledButton } from 'src/styles';
import { User } from 'src/types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'src/hooks/use-auth';
import { Formik, Form } from 'formik';
import { useUnfollowUser } from 'src/hooks/use-unfollow-user';
import { useFollowUser } from 'src/hooks/use-follow-user';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const StyledForm = styled(Form)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const RestyledButton = styled(StyledButton)`
  width: 100%;
`;

interface InterfaceProps {
  toggleEdit: () => void;
  user: User;
}

const UserSummaryPrimaryButton: React.FC<InterfaceProps> = ({
  toggleEdit,
  user,
}) => {
  const { user: authUser } = useAuth();
  const { t } = useTranslation('general');
  const onUnfollow = useUnfollowUser(user);
  const onFollow = useFollowUser(user);
  const currentUserIsUser = authUser && authUser.id === user.id;
  const [isFollowing, setIsFollowing] = useState(user.userHasFollowed);
  const follow = useCallback(async () => {
    await onFollow(null);
    setIsFollowing(true);
  }, [setIsFollowing]);
  const unfollow = useCallback(async () => {
    await onUnfollow(null);
    setIsFollowing(false);
  }, [setIsFollowing]);

  if (currentUserIsUser) {
    return (
      <StyledButton onClick={toggleEdit}>{t('profilesEdit')}</StyledButton>
    );
  } else {
    return (
      <Container>
        {isFollowing ? (
          <Formik initialValues={{}} onSubmit={unfollow}>
            {({ isSubmitting }): ReactElement => (
              <StyledForm>
                <RestyledButton type="submit" disabled={isSubmitting}>
                  {t('followUnfollow')}
                </RestyledButton>
              </StyledForm>
            )}
          </Formik>
        ) : (
          <Formik initialValues={{}} onSubmit={follow}>
            {({ isSubmitting }): ReactElement => (
              <StyledForm>
                <RestyledButton type="submit" disabled={isSubmitting}>
                  {t('followFollow')}
                </RestyledButton>
              </StyledForm>
            )}
          </Formik>
        )}
      </Container>
    );
  }
};

export default UserSummaryPrimaryButton;
