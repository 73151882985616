import React, { useContext, createContext } from 'react';
import { Socket } from 'phoenix';
import config from 'src/config';

const socket = new Socket(
  `ws://${config.isLocalDev ? '' : window.location.host}${config.socketlUrl}`,
  {}
);

socket.connect();

const SocketContext = createContext<Socket>(socket);

export const useSocket = (): Socket => {
  return useContext(SocketContext);
};

export const SocketProvider: React.FC = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
