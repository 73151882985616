/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import NotificationListView from 'src/components/notification-list/notification-list-view';
import { PaginationArgs, Notification } from 'src/types';
import { SEE_NOTIFICATIONS_MUTATION } from 'src/queries/notifications';
import { useMutation } from '@apollo/client';
import { CURRENT_USER_QUERY } from 'src/queries/account';
import { useAuth } from 'src/hooks/use-auth';

interface InterfaceProps {
  className?: string;
  data: any;
  onClick?: () => void;
  paginationArgs?: PaginationArgs;
}

const NotificationListQueryInner: React.FC<InterfaceProps> = ({
  className,
  data,
  onClick,
}) => {
  const { user } = useAuth();
  const [seeNotifications, { called }] = useMutation(
    SEE_NOTIFICATIONS_MUTATION,
    {
      update(cache) {
        if (!user) return;

        cache.writeQuery({
          query: CURRENT_USER_QUERY,
          data: {
            currentUser: {
              ...user,
              hasUnseenNotifications: false,
            },
          },
        });
      },
    }
  );
  const unseenNotifications = (data.entries as Notification[]).filter(
    (notification) => {
      return !notification.isSeen;
    }
  );

  if (user?.hasUnseenNotifications && !called) {
    seeNotifications({
      variables: {
        notificationIds: unseenNotifications.map(
          (notification) => notification.id
        ),
      },
    });
  }

  return (
    <NotificationListView className={className} onClick={onClick} {...data} />
  );
};

export default NotificationListQueryInner;
