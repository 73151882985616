/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { FilePreviewMediaType, MediaItem } from 'src/types';
import VideoPlayer from 'src/components/video-player';
import NoContent from 'src/components/no-content';
import styled from 'styled-components';
import { GlobalStyleVariables } from 'src/styles';

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  cursor: pointer;
  color: rgba(${(props): string => props.theme.primaryColor}, 1);
`;

const NoContentText = styled.div`
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

interface InterfaceProps {
  canClickToPlay?: boolean;
  className?: string;
  isVisible: boolean;
  mediaItem: MediaItem;
  noContentText?: string;
  shouldMute?: boolean;
  shouldPlayOnView?: boolean;
  noContentClickCb?: (_: any, { setSubmitting }: any) => Promise<void>;
}

const MediaItemElementView: React.FC<InterfaceProps> = ({
  canClickToPlay,
  className,
  isVisible,
  mediaItem,
  noContentText,
  shouldMute,
  shouldPlayOnView,
  noContentClickCb,
}) => {
  if (!mediaItem) return <></>;

  function renderContent(): ReactElement {
    if (
      mediaItem.image?.src ||
      mediaItem.image?.previewSrc ||
      (mediaItem.video?.previewMediaType === FilePreviewMediaType.image &&
        !mediaItem.video?.src)
    ) {
      return (
        <img
          key={mediaItem.id}
          src={
            mediaItem.image
              ? !mediaItem.image.src
                ? mediaItem.image.previewSrc
                : mediaItem.image.src
              : mediaItem.video && mediaItem.video.previewSrc
          }
          alt={mediaItem.image?.caption ?? ''}
          className={className}
        />
      );
    } else if (mediaItem.video?.src || mediaItem.video?.previewSrc) {
      return (
        <VideoPlayer
          key={mediaItem.id}
          canClickToPlay={canClickToPlay}
          shouldMute={shouldMute}
          shouldPause={!isVisible}
          shouldPlayOnView={shouldPlayOnView}
          src={
            (!mediaItem.video.src
              ? mediaItem.video.previewSrc
              : mediaItem.video.src) as string
          }
          className={className}
        />
      );
    } else {
      return (
        <NoContent icon="lock">
          <NoContentText>{noContentText}</NoContentText>
        </NoContent>
      );
    }
  }

  const needsPurchase =
    (mediaItem.image && !mediaItem.image.src) ||
    (mediaItem.video && !mediaItem.video.src);

  if (needsPurchase) {
    return (
      <StyledButton
        onClick={() => (noContentClickCb ? noContentClickCb(null, {}) : null)}
      >
        {renderContent()}
      </StyledButton>
    );
  } else {
    return renderContent();
  }
};

export default MediaItemElementView;
