/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import ResponsiveCanvas from 'src/components/responsive-canvas/index';
import { FileFilter, FileUpload, SimpleFile } from 'src/types';
import { renderModifiedImage } from 'src/utils/uploads';
import NoContent from '../no-content';

const Container = styled.div`
  position: relative;
`;

const Canvas = styled(ResponsiveCanvas)`
  height: 100%;
`;

const ImageElement = styled.img`
  height: 100%;
`;

const StyledNoContent = styled(NoContent)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface InterfaceProps {
  className?: string;
  file?: FileUpload;
  mode?: string;
  onClick?: () => void;
  originalFile?: SimpleFile;
  [x: string]: any;
}

const FileUploaderImage: React.FC<InterfaceProps> = ({
  className,
  file,
  mode,
  onClick,
  originalFile,
  ...props
}) => {
  const render = useCallback(
    (canvas: HTMLCanvasElement): void => {
      if (file) {
        const image = new Image();
        image.src = file.result;
        const innerRender = () => {
          if (image.width > 0) {
            renderModifiedImage(file, canvas, image, {
              shouldUseCanvasHeight: true,
              shouldApplyFilter: mode === 'filter',
            });
          }
        };

        innerRender();

        image.onload = innerRender;
      }
    },
    [file, file?.previewType, mode]
  );

  if (file) {
    return (
      <Container {...props}>
        <Canvas className={className} render={render} onClick={onClick} />
        {file.previewType === FileFilter.locked && mode === 'filter' && (
          <StyledNoContent icon="lock">
            <div />
          </StyledNoContent>
        )}
      </Container>
    );
  } else if (originalFile) {
    return <ImageElement className={className} src={originalFile.src} />;
  }

  return <></>;
};

export default FileUploaderImage;
