import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowsAltH,
  faArrowsAltV,
  faBars,
  faBell,
  faBookDead,
  faBug,
  faCamera,
  faCaretDown,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faCircleXmark,
  faClock,
  faComment,
  faCompress,
  faDiceD20,
  faEllipsisH,
  faExpand,
  faEye,
  faEyeSlash,
  faGhost,
  faLink,
  faList,
  faLock,
  faPause,
  faPen,
  faPhotoVideo,
  faPlay,
  faPlus,
  faRss,
  faSearch,
  faShare,
  faSlash,
  faSpinner,
  faStar,
  faSyncAlt,
  faTimes,
  faDollarSign,
  faUnlock,
  faUpload,
  faUserPlus,
  faUserTimes,
  faVolumeOff,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons';
import {
  faSquare,
  faStar as faStarRegular,
} from '@fortawesome/free-regular-svg-icons';

export default function setupFontLibrary(): void {
  library.add(
    faArrowsAltH,
    faArrowsAltV,
    faBars,
    faBell,
    faBookDead,
    faBug,
    faCamera,
    faCaretDown,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleXmark,
    faClock,
    faComment,
    faCompress,
    faDiceD20,
    faEllipsisH,
    faExpand,
    faEye,
    faEyeSlash,
    faGhost,
    faLink,
    faList,
    faLock,
    faPause,
    faPen,
    faPhotoVideo,
    faPlay,
    faPlus,
    faRss,
    faSearch,
    faShare,
    faSlash,
    faSpinner,
    faSquare,
    faStar,
    faStarRegular,
    faSyncAlt,
    faTimes,
    faDollarSign,
    faUnlock,
    faUpload,
    faUserPlus,
    faUserTimes,
    faVolumeOff,
    faVolumeUp
  );
}
