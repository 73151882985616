import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { Formik, Form } from 'formik';
import {
  MinorMajorButtonSet,
  StyledButton,
  StyledError,
  DeemphasizedButton,
  GlobalStyleVariables,
  Ellipsis,
} from 'src/styles';
import { useGraphQLErrors, Error } from 'src/hooks/use-graqphql-errors';
import {
  GenericOptionItem,
  SubscriptionDiscount,
  SubscriptionDiscountType,
  PurchaseUserSubscriptionInput,
  User,
} from 'src/types';
import { SimpleDeconstructedSelect } from 'src/components/simple-form';
import { useQuery } from '@apollo/client';
import { SUBSCRIPTION_DISCOUNTS_QUERY } from 'src/queries';
import GraphqlResult from '../graphql-result';
import { usePurchaseUserSubscription } from 'src/hooks/use-purchase-user-subscription';

const StyledForm = styled(Form)`
  padding: ${fibonacci(3)}rem;
`;

const Title = styled.div`
  font-size: ${GlobalStyleVariables.fontSizeThreeUp}rem;
  margin-bottom: 1rem;
`;

const About = styled.div`
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  margin-bottom: 1rem;
  opacity: ${goldenRatioInverse};
`;

const About2 = styled.div`
  font-size: ${GlobalStyleVariables.fontSizeOneUp}rem;
  margin-bottom: ${fibonacci(3)}rem;
  opacity: ${goldenRatioInverse};
`;

const StyledButtonSet = styled(MinorMajorButtonSet)`
  margin-top: ${fibonacci(3)}rem;
`;

const DiscountDescription = styled.div`
  margin-bottom: 1rem;
`;

const DiscountOptions = styled.div`
  margin-top: 1rem;
  margin-bottom: ${fibonacci(3)}rem;
  display: flex;
  align-items: center;
`;

interface InterfaceProps {
  subscribed: User;
  onReject: () => void;
  onResolve: (subscribed: boolean) => void;
}

const SubscriptionFormView: React.FC<InterfaceProps> = ({
  subscribed,
  onReject,
  onResolve,
}) => {
  const { t } = useTranslation('general');
  const handleGraphQLErrors = useGraphQLErrors();
  const [globalError, setGlobalError] = useState<string | null>(null);
  const purchaseUserSubscription = usePurchaseUserSubscription();

  const subscriptionDiscountsResponse = useQuery(SUBSCRIPTION_DISCOUNTS_QUERY, {
    variables: {
      isActive: true,
      userId: subscribed?.id,
    },
  });

  return (
    <GraphqlResult response={subscriptionDiscountsResponse}>
      {({ subscriptionDiscounts }): ReactElement => {
        const promotion = subscriptionDiscounts
          ? subscriptionDiscounts.find(
              (sd: SubscriptionDiscount) =>
                sd.type === SubscriptionDiscountType.initialSubscription
            )
          : null;
        const twoMonthPackage = subscriptionDiscounts
          ? subscriptionDiscounts.find(
              (sd: SubscriptionDiscount) =>
                sd.type === SubscriptionDiscountType.twoMonthPackage
            )
          : null;
        const threeMonthPackage = subscriptionDiscounts
          ? subscriptionDiscounts.find(
              (sd: SubscriptionDiscount) =>
                sd.type === SubscriptionDiscountType.threeMonthPackage
            )
          : null;

        const onSubmit = async (
          values: PurchaseUserSubscriptionInput,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { setSubmitting }: any
        ): Promise<void> => {
          try {
            if (!purchaseUserSubscription) return;

            setGlobalError(null);

            await purchaseUserSubscription(values);

            onResolve(true);
          } catch (e) {
            handleGraphQLErrors(e as Error, (message: string) => {
              setGlobalError(message);
            });
          } finally {
            setSubmitting(false);
          }
        };

        const subscriptionDiscountOptions = [] as GenericOptionItem[];

        if (promotion) {
          subscriptionDiscountOptions.push({
            label: t('purchasedUserSubscriptionDiscountPromotion', {
              duration: promotion.duration,
              promotedAmount:
                subscribed.subscriptionPrice *
                (promotion.percentage / 100) *
                (promotion.duration / 31),
            }),
            value: promotion.id,
          });
        }

        if (twoMonthPackage) {
          subscriptionDiscountOptions.push({
            label: t('purchasedUserSubscriptionDiscountTwoMonthPackage', {
              amount:
                subscribed.subscriptionPrice *
                (2 * (twoMonthPackage.percentage / 100)),
            }),
            value: twoMonthPackage.id,
          });
        }

        if (threeMonthPackage) {
          subscriptionDiscountOptions.push({
            label: t('purchasedUserSubscriptionDiscountThreeMonthPackage', {
              amount:
                subscribed.subscriptionPrice *
                (3 * (threeMonthPackage.percentage / 100)),
            }),
            value: threeMonthPackage.id,
          });
        }

        return (
          <Formik
            initialValues={{
              subscribedId: subscribed.id,
            }}
            onSubmit={onSubmit}
            validateOnBlur={false}
          >
            {({ isSubmitting, values }): ReactElement => (
              <StyledForm data-testid="purchased-user-subscription-form">
                <Title>
                  <Ellipsis>
                    {t('purchasedUserSubscriptionTitle', {
                      username: subscribed.username,
                    })}
                  </Ellipsis>
                </Title>
                <About>
                  {t('purchasedUserSubscriptionPrice', {
                    amount: subscribed.subscriptionPrice,
                  })}
                </About>
                <About2>
                  {t('purchasedUserSubscriptionCount', {
                    count: subscribed.subscriptionPostCount,
                  })}
                </About2>
                {subscriptionDiscountOptions.length > 0 && (
                  <>
                    <DiscountDescription>
                      {t('purchasedUserSubscriptionDiscounts')}
                    </DiscountDescription>
                    <DiscountOptions>
                      <SimpleDeconstructedSelect
                        name="subscriptionDiscountId"
                        options={subscriptionDiscountOptions}
                      />
                    </DiscountOptions>
                  </>
                )}
                {globalError && <StyledError>{globalError}</StyledError>}
                <StyledButtonSet>
                  <DeemphasizedButton
                    data-testid="subscription-package-form__cancel"
                    type="button"
                    onClick={onReject}
                  >
                    {t('cancel')}
                  </DeemphasizedButton>
                  <StyledButton
                    data-testid="subscription-package-form__submit"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t('submit')}
                  </StyledButton>
                </StyledButtonSet>
              </StyledForm>
            )}
          </Formik>
        );
      }}
    </GraphqlResult>
  );
};

export default SubscriptionFormView;
