/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { InvertingHoverButton } from 'src/styles';
import { User } from 'src/types';
import { useAuth } from 'src/hooks/use-auth';
import { Formik, Form } from 'formik';
import { useReloadAndViewUser } from 'src/hooks/use-reload-and-view-user';
import { useSubscribe } from 'src/hooks/use-subscribe';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledForm = styled(Form)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const StyledInvertingHoverButton = styled(InvertingHoverButton)`
  height: 100%;

  &:disabled {
    border-color: rgba(${(props): string => props.theme.primaryColor}, 1);

    &:hover,
    &:focus {
      background-color: rgba(
        ${(props): string => props.theme.backgroundColor},
        1
      );
      color: rgba(${(props): string => props.theme.lowMutedColor}, 1);
    }
  }
`;

interface InterfaceProps {
  user: User;
}

const UserSummarySubscribeButton: React.FC<InterfaceProps> = ({ user }) => {
  const { user: authUser } = useAuth();
  const onSubscribe = useSubscribe(user);
  const onReloadCurrentUser = useReloadAndViewUser(user);
  const currentUserIsUser = authUser && authUser.id === user.id;
  const latestSubscription = authUser
    ? authUser.activeUserSubscriptions
        .filter((sub) => sub.subscribed.id === user.id)
        .sort((a, b) => {
          return (
            new Date(a.activeUntil).getTime() -
            new Date(b.activeUntil).getTime()
          );
        })[0]
    : undefined;

  async function onSubscribeAndReload() {
    await onSubscribe(null, {});
    await onReloadCurrentUser();
  }

  if (currentUserIsUser || user.subscriptionPostCount <= 0) {
    return <></>;
  } else {
    return (
      <Formik initialValues={{}} onSubmit={onSubscribeAndReload}>
        {({ isSubmitting }): ReactElement => (
          <StyledForm>
            <StyledInvertingHoverButton
              type="submit"
              disabled={isSubmitting || !!latestSubscription}
            >
              <FontAwesomeIcon icon={latestSubscription ? 'unlock' : 'lock'} />
            </StyledInvertingHoverButton>
          </StyledForm>
        )}
      </Formik>
    );
  }
};

export default UserSummarySubscribeButton;
