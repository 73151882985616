/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  WheelEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  TouchEvent,
  useReducer,
} from 'react';
import styled from 'styled-components';
import { useDoubleTap } from 'use-double-tap';
import { FileFilter, FileUpload } from 'src/types';
import FileUploaderImage from 'src/components/file-uploader/file-uploader-image';
import FileUploaderVideo from 'src/components/file-uploader/file-uploader-video';
import { PrimaryColorVanillaLink } from 'src/styles/components/link';
import { fibonacci, goldenRatioInverse } from 'src/utils/math';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragIndexContainer } from 'src/components/file-uploader/queue/file-uploader-queue-carousel';
import Keyhole from 'src/components/icons/keyhole';
import { StyledRangeNativeInput } from 'src/styles';

interface ContainerProps {
  isDragging: boolean;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  isDragging: props.isDragging || false,
}))`
  position: relative;
  height: 100%;
  cursor: grab;
  opacity: ${(props): number => (props.isDragging ? 0 : 1)};
  z-index: 2;
`;

interface ButtonProps {
  isSelected: boolean;
}

const Button = styled(PrimaryColorVanillaLink).attrs((props: ButtonProps) => ({
  isSelected: props.isSelected || false,
}))`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${fibonacci(4)}rem;
  width: ${fibonacci(4)}rem;
  border-radius: 50%;
  color: rgba(
    ${(props): string =>
      props.isSelected
        ? props.theme.backgroundColor
        : props.theme.primaryColor},
    1
  );
  background-color: rgba(
    ${(props): string =>
      props.isSelected
        ? props.theme.primaryColor
        : props.theme.backgroundColor},
    ${(props): string | number => (props.isSelected ? 1 : goldenRatioInverse)}
  );
`;

const RemoveButton = styled(Button)`
  top: 1rem;
  right: 1rem;
`;

const EditButton = styled(Button)`
  bottom: 1rem;
  right: 1rem;
`;

const FilterRow = styled.div`
  position: absolute;
  right: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 2;

  a {
    position: relative;
    height: ${fibonacci(4)}rem;
    width: ${fibonacci(4)}rem;
    margin-top: 1rem;
    padding: ${goldenRatioInverse}rem;
    fill: rgba(${(props): string => props.theme.primaryColor}, 1);
  }
`;

const FilterRangeContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  label {
    margin-bottom: 0;

    input {
      margin: 1rem 0;
    }
  }
`;

export interface DragItem {
  index: number;
  type: string;
}

interface InterfaceProps {
  addFilesToQueue: (files: FileList, index?: number) => Promise<void>;
  dragIndexContainer: DragIndexContainer;
  file: FileUpload;
  index: number;
  isEditing?: boolean;
  mode: string;
  moveItem: (hoverIndex: number) => void;
  onDrag: (index: number) => void;
  removeFile: (index: number) => void;
  replaceFile: (index: number, file: FileUpload) => boolean;
  saveFilteredImage: (index: number, file: FileUpload) => void;
  clearManualFilter: (index: number, file: FileUpload) => void;
  selectFile: (file: FileUpload) => void;
}

const FileUploaderQueueItem: React.FC<InterfaceProps> = ({
  addFilesToQueue,
  dragIndexContainer,
  file,
  index,
  isEditing,
  mode,
  moveItem,
  onDrag,
  removeFile,
  replaceFile,
  saveFilteredImage,
  clearManualFilter,
  selectFile,
}) => {
  // take from:
  // https://react-dnd.github.io/react-dnd/examples/sortable/simple
  const ref = useRef<HTMLDivElement>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [isDragging, setIsDragging] = useState(false);
  const [zoomDragState, setZoomDragState] = useState({
    isDragging: false,
    startX: 0,
    startY: 0,
  });

  const dragStart = (event: DragEvent): void => {
    event.dataTransfer.dropEffect = 'move';
    onDrag(index);
    requestAnimationFrame(() => {
      setIsDragging(true);
    });
  };

  const dragOver = (event: DragEvent): void => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';

    const { dragIndex } = dragIndexContainer;

    const isDraggingFile = event.dataTransfer.types.some((type) => {
      return type === 'Files';
    });

    if (!ref.current || dragIndex === undefined || isDraggingFile) return;

    const hoverIndex = index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) return;

    // Determine rectangle on screen
    const hoverBoundingRect = ref.current.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

    // Get pixels to the top
    const hoverClientX = event.clientX - hoverBoundingRect.left;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) return;

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) return;
    moveItem(index);
  };

  const dragEnd = (event: DragEvent): void => {
    event.dataTransfer.dropEffect = 'move';
    onDrag(undefined as unknown as number);
    setIsDragging(false);
  };

  const drop = useCallback(
    (event: DragEvent): void => {
      event.preventDefault();
      addFilesToQueue(event.dataTransfer.files, index + 1);
    },
    [addFilesToQueue]
  );

  const updateZoomIntensity = (event: ChangeEvent<HTMLInputElement>) => {
    file.zoomIntensity = parseInt(event.target?.value);
    saveFilteredImage(index, file);
  };

  const mouseWheel = useCallback(
    (event: WheelEvent<HTMLDivElement>) => {
      if (mode === 'filter') {
        const zoomIntensity = file.zoomIntensity ?? 50;
        file.zoomIntensity = Math.min(
          100,
          Math.max(0, event.deltaY > 0 ? zoomIntensity - 5 : zoomIntensity + 5)
        );
        saveFilteredImage(index, file);
      }
    },
    [saveFilteredImage, file.zoomIntensity]
  );

  const moveCore = useCallback(
    (clientX: number, clientY: number, target: HTMLElement) => {
      const distanceX = (clientX - zoomDragState.startX) * -1;
      const distanceY = (clientY - zoomDragState.startY) * -1;
      const zoomIntensity = (100 - (file.zoomIntensity ?? 0)) * 4;
      const widthPercent =
        (distanceX > 0
          ? (target.offsetWidth - (target.offsetWidth - distanceX)) /
            target.offsetWidth
          : ((target.offsetWidth - (target.offsetWidth - Math.abs(distanceX))) /
              target.offsetWidth) *
            -1) * zoomIntensity;
      const heightPercent =
        (distanceY > 0
          ? (target.offsetHeight - (target.offsetHeight - distanceY)) /
            target.offsetHeight
          : ((target.offsetHeight -
              (target.offsetHeight - Math.abs(distanceY))) /
              target.offsetHeight) *
            -1) * zoomIntensity;
      file.zoomX = Math.max(
        0,
        Math.min(100, (file.zoomX ?? 50) + widthPercent)
      );
      file.zoomY = Math.max(
        0,
        Math.min(100, (file.zoomY ?? 50) + heightPercent)
      );
      setZoomDragState({
        isDragging: true,
        startX: clientX,
        startY: clientY,
      });
      saveFilteredImage(index, file);
    },
    [
      file.zoomX,
      file.zoomY,
      file.zoomIntensity,
      zoomDragState,
      setZoomDragState,
      saveFilteredImage,
      file,
      index,
    ]
  );

  const mouseDown = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (mode === 'filter') {
        setZoomDragState({
          isDragging: true,
          startX: event.clientX,
          startY: event.clientY,
        });
      }
    },
    [setZoomDragState]
  );

  const mouseMove = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (zoomDragState.isDragging) {
        const target = event.target as HTMLElement;
        moveCore(event.clientX, event.clientY, target);
      }
    },
    [moveCore]
  );

  const mouseDone = useCallback(() => {
    setZoomDragState({
      isDragging: false,
      startX: 0,
      startY: 0,
    });
  }, [setZoomDragState]);

  const touchStart = useCallback(
    (event: TouchEvent<HTMLDivElement>) => {
      if (mode === 'filter') {
        setZoomDragState({
          isDragging: true,
          startX: event.targetTouches[0].clientX,
          startY: event.targetTouches[0].clientY,
        });
      }
    },
    [setZoomDragState]
  );

  const touchMove = useCallback(
    (event: TouchEvent<HTMLElement>) => {
      if (zoomDragState.isDragging) {
        const target = event.target as HTMLElement;
        moveCore(
          event.targetTouches[0].clientX,
          event.targetTouches[0].clientY,
          target
        );
      }
    },
    [moveCore]
  );

  const touchDone = useCallback(() => {
    setZoomDragState({
      isDragging: false,
      startX: 0,
      startY: 0,
    });
  }, [setZoomDragState]);

  useEffect(() => {
    if (!file.filteredModifiedFile) {
      saveFilteredImage(index, file);
    }
  }, [file.filteredModifiedFile]);

  return (
    <Container
      ref={ref}
      {...useDoubleTap(
        (): boolean | void => mode === 'upload' && selectFile(file)
      )}
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      onDrop={drop}
      draggable={mode === 'upload'}
      isDragging={isDragging}
    >
      {file.type === 'image' && (
        <FileUploaderImage
          onMouseDown={mouseDown}
          onMouseMove={mouseMove}
          onMouseUp={mouseDone}
          onMouseOut={mouseDone}
          onWheel={mouseWheel}
          onTouchStart={touchStart}
          onTouchMove={touchMove}
          onTouchEnd={touchDone}
          onTouchCancel={touchDone}
          file={file}
          mode={mode}
        />
      )}
      {file.type === 'video' && (
        <FileUploaderVideo
          file={file}
          index={index}
          isEditing={isEditing}
          mode={mode}
          replaceFile={replaceFile}
        />
      )}
      {mode === 'upload' && (
        <>
          <RemoveButton onClick={(): void => removeFile(index)}>
            <FontAwesomeIcon icon="times" />
          </RemoveButton>
          <EditButton onClick={(): void => selectFile(file)}>
            <FontAwesomeIcon icon="pen" />
          </EditButton>
        </>
      )}
      {mode === 'filter' &&
        !(isEditing && file.previewType === FileFilter.manual) && (
          <>
            <FilterRow>
              <Button
                isSelected={file.previewType === FileFilter.none}
                onClick={() => {
                  file.previewable = true;
                  file.previewType = FileFilter.none;
                  forceUpdate();
                  saveFilteredImage(index, file);
                  clearManualFilter(index, file);
                }}
              >
                <FontAwesomeIcon icon={['far', 'square']} />
              </Button>
              {file.type === 'video' ? (
                <Button
                  isSelected={file.previewType === FileFilter.manual}
                  onClick={() => {
                    file.previewable = false;
                    file.previewType = FileFilter.manual;
                    forceUpdate();
                    saveFilteredImage(index, file);
                    clearManualFilter(index, file);
                  }}
                >
                  <FontAwesomeIcon icon="upload" />
                </Button>
              ) : (
                <Button
                  isSelected={file.previewType === FileFilter.keyhole}
                  onClick={() => {
                    file.previewable = false;
                    file.previewType = FileFilter.keyhole;
                    if (!file.zoomIntensity) file.zoomIntensity = 0;
                    if (!file.zoomX) file.zoomX = 50;
                    if (!file.zoomY) file.zoomY = 50;
                    forceUpdate();
                    saveFilteredImage(index, file);
                    clearManualFilter(index, file);
                  }}
                >
                  <Keyhole />
                </Button>
              )}
              <Button
                isSelected={file.previewType === FileFilter.locked}
                onClick={() => {
                  file.previewable = false;
                  file.previewType = FileFilter.locked;
                  forceUpdate();
                  saveFilteredImage(index, file);
                  clearManualFilter(index, file);
                }}
              >
                <FontAwesomeIcon icon="lock" />
              </Button>
            </FilterRow>
            {file.previewType === FileFilter.keyhole && file.type === 'image' && (
              <FilterRangeContainer>
                <StyledRangeNativeInput
                  min={0}
                  max={99}
                  step={1}
                  type="range"
                  value={file.zoomIntensity}
                  onChange={updateZoomIntensity}
                />
              </FilterRangeContainer>
            )}
          </>
        )}
    </Container>
  );
};

export default FileUploaderQueueItem;
