/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useContext, createContext } from 'react';
import PanedModal from 'src/components/paned-modal';
import { useTranslation } from 'react-i18next';
import { goldenRatioInverse } from 'src/utils/math';
import LoginFormAbout from 'src/components/login-form/login-form-about';
import LoginForm from 'src/components/login-form';
import RegistrationFormAbout from 'src/components/registration-form/registration-form-about';
import RegistrationForm from 'src/components/registration-form';
import SubscriptionFormAbout from 'src/components/subscription-form/subscription-form-about';
import SubscriptionForm from 'src/components/subscription-form/subscription-form-view';
import SubscriptionPackageFormAbout from 'src/components/subscription-package-form/subscription-package-form-about';
import SubscriptionPackageForm from 'src/components/subscription-package-form/subscription-package-form-view';
import SubscriptionPromotionFormAbout from 'src/components/subscription-promotion-form/subscription-promotion-form-about';
import SubscriptionPromotionForm from 'src/components/subscription-promotion-form/subscription-promotion-form-view';
import AddToCuratedListAbout from 'src/components/add-to-curated-list/add-to-curated-list-about';
import AddToCuratedList from 'src/components/add-to-curated-list';
import ReportPostFormAbout from 'src/components/report-post-form/report-post-form-about';
import ReportPostForm from 'src/components/report-post-form';
import ReportCuratedListFormAbout from 'src/components/report-curated-list-form/report-curated-list-form-about';
import ReportCuratedListForm from 'src/components/report-curated-list-form';
import ReportProfileForm from 'src/components/report-profile-form';
import ReportProfileFormAbout from 'src/components/report-profile-form/report-profile-form-about';
import ReportCommentFormAbout from 'src/components/report-comment-form/report-comment-form-about';
import ReportCommentForm from 'src/components/report-comment-form';
import { useAuth } from './use-auth';
import { isMobile } from 'src/utils/detection';

type arbitraryArgs = any[];
type ModalName =
  | 'addToCuratedList'
  | 'login'
  | 'registration'
  | 'reportPost'
  | 'reportComment'
  | 'reportCuratedList'
  | 'reportProfile'
  | 'subscribe'
  | 'subscriptionPackageForm'
  | 'subscriptionPromotionForm';

interface ModalResolutions {
  reject: () => void;
  resolve: (...args: arbitraryArgs) => void;
}

interface ContextProps {
  closeModal: () => void;
  currentModal?: string;
  currentOptions: any;
  modalResolutions?: ModalResolutions;
  openModal: (modalName: ModalName, options?: object) => Promise<any>;
}

const AccountModalContext = createContext<Partial<ContextProps>>({});

export const useAccountModals = (): Partial<ContextProps> => {
  return useContext(AccountModalContext);
};

function useAccountModalsProvider(): ContextProps {
  const [currentModal, setCurrentModal] = useState<string | undefined>();
  const [currentOptions, setCurrentOptions] = useState({});
  const [modalResolutions, setModalResolutions] = useState<
    ModalResolutions | undefined
  >();

  const closeModal = (): void => {
    setCurrentModal(undefined);
  };

  const openModal = (modalName: ModalName, options = {}): Promise<any> => {
    return new Promise((resolve): void => {
      setModalResolutions({
        reject: () => resolve(null),
        resolve,
      });

      setCurrentOptions(options);
      setCurrentModal(modalName);
    });
  };

  return {
    closeModal,
    currentModal,
    currentOptions,
    modalResolutions,
    openModal,
  };
}

export const AccountModalsProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('general');
  const accountModals = useAccountModalsProvider();
  const { user } = useAuth();
  const goToRegisterForm = (): void => {
    accountModals.openModal('registration');
  };
  const goToSubscriptionPackageForm = (): void => {
    accountModals.openModal('subscriptionPackageForm');
  };
  const goToSubscriptionPromotionForm = (): void => {
    accountModals.openModal('subscriptionPromotionForm');
  };
  const goToLoginForm = (): void => {
    accountModals.openModal('login');
  };
  const onReject = (): void => {
    if (accountModals) {
      if (accountModals.modalResolutions) {
        accountModals.modalResolutions.reject();
      }
      accountModals.closeModal();
    }
  };
  const onResolve = (...args: arbitraryArgs): void => {
    if (accountModals) {
      if (accountModals.modalResolutions)
        accountModals.modalResolutions.resolve(...args);
      accountModals.closeModal();
    }
  };

  const addToCuratedListPanels = [];

  if (user?.curatedListCount === 0 || !isMobile()) {
    addToCuratedListPanels.push({
      basis: goldenRatioInverse * 100,
      element: <AddToCuratedListAbout key={1} />,
    });
  }

  addToCuratedListPanels.push({
    basis: 100 - goldenRatioInverse * 100,
    element: (
      <AddToCuratedList
        key={2}
        post={accountModals.currentOptions.post}
        hasListed={accountModals.currentOptions.hasListed}
        onResolve={onResolve}
        onReject={onReject}
      />
    ),
  });

  return (
    <AccountModalContext.Provider value={accountModals}>
      <PanedModal
        isOpen={accountModals.currentModal === 'addToCuratedList'}
        closeModal={onReject}
        contentLabel={t('curatedListsAddTo')}
      >
        {addToCuratedListPanels}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'login'}
        closeModal={onReject}
        contentLabel={t('login')}
      >
        {[
          {
            basis: goldenRatioInverse * 100,
            element: (
              <LoginFormAbout key={1} goToRegisterForm={goToRegisterForm} />
            ),
          },
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <LoginForm key={2} onResolve={onResolve} onReject={onReject} />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'subscribe'}
        closeModal={onReject}
        contentLabel={t('subscribeSubscribe')}
      >
        {[
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <SubscriptionFormAbout
                key={1}
                followed={accountModals.currentOptions.subscribed}
              />
            ),
          },
          {
            basis: goldenRatioInverse * 100,
            element: (
              <SubscriptionForm
                key={2}
                onResolve={onResolve}
                onReject={onReject}
                subscribed={accountModals.currentOptions.subscribed}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'subscriptionPackageForm'}
        closeModal={onReject}
        contentLabel={t('subscriptionPackageForm')}
      >
        {[
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <SubscriptionPackageFormAbout
                key={1}
                goToSubscriptionPromotionForm={goToSubscriptionPromotionForm}
              />
            ),
          },
          {
            basis: goldenRatioInverse * 100,
            element: (
              <SubscriptionPackageForm
                key={2}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'subscriptionPromotionForm'}
        closeModal={onReject}
        contentLabel={t('subscriptionPromotionForm')}
      >
        {[
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <SubscriptionPromotionFormAbout
                key={1}
                goToSubscriptionPackageForm={goToSubscriptionPackageForm}
              />
            ),
          },
          {
            basis: goldenRatioInverse * 100,
            element: (
              <SubscriptionPromotionForm
                key={2}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'registration'}
        closeModal={onReject}
        contentLabel={t('register')}
      >
        {[
          {
            basis: goldenRatioInverse * 100,
            element: (
              <RegistrationFormAbout key={1} goToLoginForm={goToLoginForm} />
            ),
          },
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <RegistrationForm
                key={2}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'reportPost'}
        closeModal={onReject}
        contentLabel={t('postsReport')}
      >
        {[
          {
            basis: goldenRatioInverse * 100,
            element: <ReportPostFormAbout key={1} />,
          },
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <ReportPostForm
                key={2}
                post={accountModals.currentOptions.post}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'reportComment'}
        closeModal={onReject}
        contentLabel={t('commentsReport')}
      >
        {[
          {
            basis: goldenRatioInverse * 100,
            element: <ReportCommentFormAbout key={1} />,
          },
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <ReportCommentForm
                key={2}
                comment={accountModals.currentOptions.comment}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'reportCuratedList'}
        closeModal={onReject}
        contentLabel={t('curatedListsReport')}
      >
        {[
          {
            basis: goldenRatioInverse * 100,
            element: <ReportCuratedListFormAbout key={1} />,
          },
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <ReportCuratedListForm
                key={2}
                curatedList={accountModals.currentOptions.curatedList}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      <PanedModal
        isOpen={accountModals.currentModal === 'reportProfile'}
        closeModal={onReject}
        contentLabel={t('profilesReport')}
      >
        {[
          {
            basis: goldenRatioInverse * 100,
            element: <ReportProfileFormAbout key={1} />,
          },
          {
            basis: 100 - goldenRatioInverse * 100,
            element: (
              <ReportProfileForm
                key={2}
                profile={accountModals.currentOptions.profile}
                onResolve={onResolve}
                onReject={onReject}
              />
            ),
          },
        ]}
      </PanedModal>

      {children}
    </AccountModalContext.Provider>
  );
};
